<template>
  <portal to="portalTarget">
    <modal :isModalOpen="isModalOpen">
      <div
        class="relative mx-4 w-full max-w-sm flex flex-col bg-white rounded-lg p-8"
      >
        <div class="flex flex-col items-center">
          <h3 class="text-xl mb-4 font-medium text-gray-700">
            <b>Please confirm before buying alcohol</b>
          </h3>
          <div class="ml-3">
            <div class="mt-2 text-sm text-gray-500">
              <p>
                You should be of legal drinking age to add alcoholic items to
                your cart
              </p>
            </div>
            <fieldset>
              <div class="divide-y divide-gray-200">
                <div class="relative flex items-start py-4">
                  <div class="min-w-0 flex-1 text-sm">
                    <label for="comments" class="font-medium text-gray-700"
                      >I confirm that I am over 21 years old, not a muslim, and
                      agree to à la carte's

                      <a
                        href="https://merchant.revenuemonster.my/docs/terms-of-use"
                        class="text-blue-500 bold hover:underline hover:text-gray-800"
                        alt="Terms of Use"
                        >Terms of Use</a
                      >
                      and

                      <a
                        href="https://alacarte.my/privacy"
                        class="text-blue-500 bold hover:underline hover:text-gray-800"
                        alt="Privacy Notice"
                        >Privacy Notice</a
                      > </label
                    >.
                  </div>
                  <div class="ml-3 flex h-5 items-center">
                    <input
                      id="comments"
                      aria-describedby="comments-description"
                      name="comments"
                      type="checkbox"
                      class="h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-500"
                      v-model="isAgree"
                    />
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </div>

        <div class="flex items-center justify-end space-x-4">
          <button
            type="button"
            class="inline-flex justify-center rounded-md border border-transparent bg-primary-400 px-4 py-2 text-sm font-medium hover:bg-primary-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-600 focus-visible:ring-offset-2"
            @click="handleCancel"
          >
            Cancel
          </button>
          <button
            type="button"
            class="inline-flex justify-center rounded-md border border-transparent bg-primary-400 px-4 py-2 text-sm font-medium hover:bg-primary-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-600 focus-visible:ring-offset-2"
            @click="handleConfirm"
            :disabled="!isAgree"
          >
            Confirm
          </button>
        </div>
      </div>
    </modal>
  </portal>
</template>

<script>
import Modal from "@/components/Feedback/Modal.vue";

export default {
  name: "AlcoholModal",
  components: {
    Modal
  },
  props: {
    isModalOpen: { type: Boolean, default: false }
  },
  data() {
    return { isAgree: false };
  },
  methods: {
    handleCancel() {
      this.$emit("cancel");
    },
    handleConfirm() {
      this.$emit("confirm");
    }
  }
};
</script>

<style lang="scss"></style>
