
import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { mixin as clickaway } from "vue-clickaway";
import SearchBar from "@/components/General/SearchBar.vue";
@Component({
  components: {
    SearchBar
  }
})
export default class Search extends mixins(clickaway) {}
