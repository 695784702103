
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class Disclosure extends Vue {
  @Prop({
    required: false,
    default: false
  })
  open?: boolean;

  isOpen = this.open;
  toggle() {
    this.isOpen = !this.isOpen;
    this.$emit("change", this.isOpen);
  }
}
