
/* eslint-disable prefer-const */
import { Component, Watch, Vue } from "vue-property-decorator";
import ICart, {
  SelectedAttribute,
  SelectedFood,
  SelectedSide,
  SelectedSideItem
} from "@/store/models/cart";
import IFood, {
  AttributesEntity,
  LabelsEntity,
  SidesEntity,
  ItemsEntity
} from "@/store/models/food";

import { cartStore } from "../store/modules/cart";
import { foodStore } from "../store/modules/food";

import dayjs, { Dayjs } from "dayjs";
import linkifyHtml from "linkifyjs/html";
import { Picture } from "@/components";
import { merchantStore } from "@/store/modules/merchant";
import { isNestle, miniProgramPlatforms } from "@/constant/platform";
import empty from "is-empty";
import { menuStore } from "@/store/modules/menu";
import { getEmptyFoodPath } from "@/components/General/DynamicImages.vue";
import cookies from "@/utils/cookies";
import Disclosure from "@/components/General/Disclosure.vue";
import DatePick from "vue-date-pick";
import customParseFormat from "dayjs/plugin/customParseFormat";
import AlcoholModal from "@/components/Custom/AlcoholModal.vue";
import Fancybox from "@/components/Custom/Fancybox.vue";

@Component({
  components: {
    Picture,
    Disclosure,
    DatePick,
    AlcoholModal,
    Fancybox
  }
})
export default class FoodView extends Vue {
  // constants
  IMAGES_PER_VIEW = 4;

  // states
  campaignID: string | null = "";
  campaignPrice = -1;
  cartId = "";
  loading = true;
  food: IFood | null = null;
  quantity = 0;
  quantitySum = 0;
  isEdit = false;
  combinationPrice = 0;
  remark = "";
  dineTime = "";
  dineType = "";
  isFirstDaySelected = false;
  isFoodProcessed = false;
  selectedFood = {
    id: "",
    attributes: [] as SelectedAttribute[],
    sides: [] as SelectedSide[],
    price: {
      currencyType: "",
      amount: 0
    }
  } as SelectedFood;
  noDisabledDays = 0;
  mealPlanID = 0;
  minDate = dayjs();
  startingMinDate = dayjs(); // for the start date calendar to be able to pick dates before minDate
  comboTaxAmount = 0;
  foodId = "";
  focusedImage = "";
  startImgIndex = 0;
  alcoholModalAction: Function | null = null;

  get foodImagesInView() {
    this.loading = true;
    let foodInView = this.food?.imageUrl?.slice(
      this.startImgIndex,
      this.startImgIndex + this.IMAGES_PER_VIEW
    );
    this.loading = false;
    return foodInView;
  }

  get isLeftDisabled() {
    return this.startImgIndex <= 0;
  }

  get isRightDisabled() {
    return (
      this.startImgIndex + this.IMAGES_PER_VIEW >=
      (this.food?.imageUrl?.length || 0)
    );
  }

  get isMealPlan() {
    return this.food?.supportedDineTypes?.includes("MEAL_PLAN") || false;
  }

  get isCampaignPrice() {
    if (this.food) {
      const price = this.calculateTotalPrice;
      this.campaignID = menuStore.getCampaignID(this.food.campaignIDs, price);
      this.campaignPrice = menuStore.getCampaignPrice(this.campaignID, price);
    }
    return this.campaignPrice > -1;
  }
  get cartStore() {
    return cartStore;
  }

  get dayjs() {
    return dayjs;
  }
  get windowTitle() {
    return (
      merchantStore.currentStore?.name +
      " - " +
      merchantStore.merchant?.company.name
    );
  }

  get isNestle() {
    return isNestle();
  }

  setFocusedImg(img) {
    this.focusedImage = img;
  }

  itemHasAttributes(item) {
    return item?.attributes?.length > 0;
  }

  itemHasSides(item) {
    return item?.sides?.length > 0;
  }

  onImgLoadError(event) {
    event.target.src = getEmptyFoodPath();
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
  }

  unavailableText() {
    const food = this.food;
    if (!food?.isAvailable && food?.nextAvailableSlot != "") {
      // Calculate the next available schedule, nearest to current hour
      const timeFormat = this.$i18n.locale == "cn" ? "A h:mm" : "h:mm A";
      const dayFormat =
        this.$i18n.locale == "cn" ? "M月D日, A h:mm" : "MMM D, h:mm A";
      const now = dayjs();
      const nextSlot = dayjs(food?.nextAvailableSlot);

      if (nextSlot.isAfter(now)) {
        const format = now.isSame(nextSlot, "day") ? timeFormat : dayFormat;
        return this.$t("FoodItem.available-time", {
          time: nextSlot.format(format)
        });
      }

      return this.$t("FoodItem.unavailable");
    }
    return this.$t("FoodItem.out-of-stock");
  }

  get deliveryTime() {
    return foodStore.deliveryTime;
  }

  findMealPlanDates(isSetMealPlanDate = true) {
    let date = this.minDate.toDate();
    let enabledDays = 0;
    this.noDisabledDays = 0;
    for (let j = 0; j <= 365; j++) {
      if (this.isDateDisabled(date)) {
        this.noDisabledDays++;
      } else {
        if (
          this.food &&
          this.food.sides &&
          this.food?.sides?.length > enabledDays &&
          this.food.sides[enabledDays]
        ) {
          this.food.sides[enabledDays]!.delivery = isSetMealPlanDate
            ? {
                dineTime: this.dineTime,
                deliverAt: dayjs(date).toISOString()
              }
            : this.selectedFood.sides[enabledDays].delivery;
          if (this.selectedFood.sides[enabledDays]) {
            this.selectedFood.sides[enabledDays].delivery = this.food.sides[
              enabledDays
            ]!.delivery;
          }
        }
        enabledDays++;
        if (enabledDays == (this.food?.mealPlan?.validity || 10080) / 1440) {
          return;
        }
      }
      date = dayjs(date)
        .add(1, "day")
        .toDate();
    }
    alert("no available delivery time");
    this.$router.go(-1);
  }

  setMinDate() {
    if (this.food?.leadTime && this.food?.leadTime > 0) {
      this.startingMinDate = dayjs()
        .startOf("day")
        .add(this.food?.leadTime, "minute");
    } else {
      this.startingMinDate = dayjs()
        .startOf("day")
        .add(1, "day");
    }

    if (dayjs().isAfter(dayjs("17:00", "HH:mm"))) {
      this.startingMinDate = dayjs().add(1, "day");
      this.minDate = this.minDate.add(1, "day");
    }
  }

  get maxDate() {
    return this.minDate
      .add(this.food?.mealPlan?.validity || 10080, "minute")
      .subtract(1, "day") // first day is included in the validity
      .add(this.noDisabledDays, "day");
  }

  noBizHour(date) {
    return foodStore.filteredDeliveryTime.some((time) => time.isSame(date));
  }

  isPublicHoliday(date: Date) {
    const djsDate = dayjs(date);
    return (
      date.getDay() == 6 ||
      date.getDay() == 0 ||
      djsDate.isBefore(this.startingMinDate) ||
      this.noBizHour(djsDate)
    );
  }
  isDateDisabled(date: Date) {
    const djsDate = dayjs(date);
    return (
      this.isPublicHoliday(date) ||
      djsDate.isBefore(this.minDate) ||
      djsDate.isAfter(this.maxDate)
    );
  }
  get dineTimes() {
    const dineTimes = this.food?.mealPlan?.dineTimes || [];
    if (!this.dineTime) {
      this.dineTime = dineTimes?.[0] || "";
    }
    return dineTimes;
  }

  get allFoods() {
    return menuStore.allFoods;
  }

  @Watch("allFoods")
  async updateFood() {
    this.updateCampaignIDs();
  }

  updateCampaignIDs() {
    if (this.food) {
      this.food.campaignIDs =
        this.allFoods?.find((f) => f?.id == this.foodId)?.campaignIDs || [];
    }
  }
  processFood(food: IFood) {
    if (food) {
      let isSetMealPlanDate = true;
      food.campaignIDs =
        this.allFoods?.find((f) => f?.id == food.id)?.campaignIDs || [];
      const { cartId } = this.$router.currentRoute.query;

      if (cartId) {
        this.cartId = cartId as string;
        const cart = this.$store.state.cartStore.cart.find(
          (each: ICart) => each.cartItemID === cartId
        ) as ICart;
        if (cart) {
          this.isEdit = true;
          this.remark = cart.remark;
          this.quantity = cart.itemQuantity;
          this.selectedFood.sides = cart.selectedSides;
          this.selectedFood.attributes = cart.selectedAttributes;
          this.dineTime = cart.selectedDineTime;
          this.isFirstDaySelected = true;
          if (food.supportedDineTypes?.includes("MEAL_PLAN")) {
            this.minDate = dayjs(
              [...cart.selectedSides].sort((a, b) => {
                return dayjs(a.delivery?.deliverAt).diff(
                  dayjs(b.delivery?.deliverAt)
                );
              })[0]?.delivery?.deliverAt
            );
            isSetMealPlanDate = false;
          }
          // this.dineType = cart.selectedDineType;
        }
      } else if (
        (this.availableFoodQuantity > 0 || this.availableFoodQuantity < 0) &&
        !this.isFoodProcessed
      ) {
        this.isFoodProcessed = true;
        this.quantity++;
      }
      // this.dineType = this.isMealPlan ? "MEAL_PLAN" : "";
      this.dineTime = this.isMealPlan ? this.dineTime : "";

      const locale = this.$i18n.locale;
      const isUsePlatformDetail = miniProgramPlatforms.includes(
        merchantStore.platform
      );

      food.name = isUsePlatformDetail
        ? !empty(food.platformDetail?.language?.[locale]?.name)
          ? food.platformDetail?.language?.[locale]?.name
          : food.name
        : !empty(food.language?.[locale]?.name)
        ? food.language?.[locale]?.name
        : food.name;
      food.imageUrl =
        isUsePlatformDetail && !empty(food.platformDetail?.imageUrl)
          ? food.platformDetail?.imageUrl
          : food.imageUrl;
      this.focusedImage = food.imageUrl![0];
      if (food.attributes) {
        food.attributes = (food.attributes as AttributesEntity[])
          .filter((each) => {
            return (
              each?.labels &&
              each.labels.length >= each.minChoice &&
              (isUsePlatformDetail ? !empty(each.platformDetail?.name) : true)
            );
          })
          .map((each) => {
            each.name = isUsePlatformDetail
              ? !empty(each?.platformDetail?.language?.[locale]?.name)
                ? each?.platformDetail?.language?.[locale]?.name
                : each.name
              : !empty(each.language?.[locale]?.name)
              ? each.language?.[locale]?.name
              : each.name;
            each.labels = (each.labels as LabelsEntity[])
              .filter((each) => {
                return isUsePlatformDetail
                  ? !empty(each.platformDetail?.name)
                  : true;
              })
              .map((item) => {
                item.name = isUsePlatformDetail
                  ? !empty(item?.platformDetail?.language?.[locale]?.name)
                    ? item?.platformDetail?.language?.[locale]?.name
                    : item.name
                  : !empty(item.language?.[locale]?.name)
                  ? item.language?.[locale]?.name
                  : item.name;
                return item;
              });
            return each;
          });
      }

      if (food.sides) {
        food.sides = food.sides.filter((each) => {
          return (
            each?.isActive &&
            (isUsePlatformDetail ? !empty(each.platformDetail?.name) : true)
          );
        });
        (food.sides as SidesEntity[]).map((each, i) => {
          each.name = isUsePlatformDetail
            ? !empty(each?.platformDetail?.language?.[locale]?.name)
              ? each?.platformDetail?.language?.[locale]?.name
              : each.name
            : !empty(each.language?.[locale]?.name)
            ? each.language?.[locale]?.name
            : each.name;

          each.items = (each.items as ItemsEntity[])
            .filter((item) => {
              return item.isAvailable && isUsePlatformDetail
                ? !empty(each.platformDetail?.name)
                : true;
            })
            .map((item) => {
              item.name = isUsePlatformDetail
                ? !empty(item?.platformDetail?.language?.[locale]?.name)
                  ? item?.platformDetail?.language?.[locale]?.name
                  : item.name
                : !empty(item.language?.[locale]?.name)
                ? item.language?.[locale]?.name
                : item.name;
              return item;
            });
        });
        food.sides = (food.sides as SidesEntity[]).filter((each) => {
          return each.items && each.items.length > 0;
        });
      }
      if (food.attributes) {
        food.attributes = food.attributes?.filter(
          (attr) => attr?.labels?.length
        );
      }
      this.food = food;

      if (this.isMealPlan) {
        if (isSetMealPlanDate) {
          this.setMinDate();
        }
        this.findMealPlanDates(isSetMealPlanDate);
      }

      this.food?.sides?.forEach((side) => {
        if (
          side?.items?.length == 1 &&
          side.minChoice > 0 &&
          !this.selectedFood.sides.some((s) => s.id == side.id)
        ) {
          this.selectedFood.sides.push({
            id: side.id,
            name: side.name,
            description: side.description,
            items: side.items.map((sI) => {
              return {
                ...sI,
                //TODO:
                // Auto select sides and attributes
                // sI?.sides?.reduce(
                //   (acc, s) => (s && s?.items?.length == 1 ? acc.push(s) : acc),
                //   []
                // )
                sides: [],
                attributes: []
              } as SelectedSideItem;
            }) as SelectedSideItem[],
            delivery: side.delivery
          });
        }
      });

      this.food?.attributes?.forEach((attr) => {
        if (
          attr?.labels?.length == 1 &&
          !this.selectedFood.attributes.some((a) => a.id == attr.id)
        ) {
          this.selectedFood.attributes.push({
            id: attr.id,
            name: attr.name,
            description: attr.description,
            labels: attr.labels
          });
        }
      });

      this.loading = false;
    }
  }

  getSelectedAttrQty(
    attr: AttributesEntity,
    label: LabelsEntity,
    mealPlanSide: SidesEntity,
    mealPlanSideItem: ItemsEntity
  ): string {
    let foodAttributes = this.selectedFood.attributes;

    if (mealPlanSide) {
      foodAttributes =
        this.selectedFood.sides
          .find((x) => x.id === mealPlanSide.id)
          ?.items.find((y) => y.id == mealPlanSideItem.id)?.attributes || [];
    }

    let qtyStr = "";
    const foodAttr = foodAttributes.find((x) => x.id === attr.id);
    let length = 0;

    if (foodAttr) {
      length = foodAttr.labels.filter((each) => each.id === label.id).length;
    }
    qtyStr = length === 0 ? "" : length + "x";
    return qtyStr;
  }

  selectMultipleAttr(
    attr: AttributesEntity,
    label: LabelsEntity,
    e: InputEvent,
    stepQty: number,
    mealPlanSide: SidesEntity,
    mealPlanSideItem: ItemsEntity
  ) {
    e.preventDefault();
    const food = this.selectedFood;
    let foodAttr = food.attributes.find((x) => x.id === attr.id);

    let selectedMealPlanAttributes;
    if (mealPlanSide) {
      selectedMealPlanAttributes = food.sides
        .find((x) => x.id === mealPlanSide.id)
        ?.items.find((y) => y.id == mealPlanSideItem.id)?.attributes;

      if (selectedMealPlanAttributes) {
        foodAttr = selectedMealPlanAttributes.find((x) => x.id === attr.id);
      }
    }

    let counts = {};

    if (foodAttr) {
      foodAttr.labels.forEach((each) => {
        counts[each.id] = (counts[each.id] || 0) + 1;
      });
      const highestQtyItemId = Object.keys(counts).reduce(
        (a, b) => (counts[a] > counts[b] ? a : b),
        ""
      );

      if (stepQty > 0) {
        foodAttr.labels.push(label);
        if (foodAttr.labels.length > attr.maxChoice) {
          const index = foodAttr.labels.findIndex(
            (each) => each.id === highestQtyItemId
          );
          if (index > -1) foodAttr.labels.splice(index, 1);
        }
      } else {
        const index = foodAttr.labels.findIndex((each) => each.id === label.id);
        if (index > -1) foodAttr.labels.splice(index, 1);
      }
    } else {
      if (stepQty > 0) {
        const load = {
          id: attr.id,
          name: attr.name,
          description: attr.description,
          labels: [label]
        };

        if (mealPlanSide) {
          selectedMealPlanAttributes?.push(load);
        } else {
          food.attributes.push(load);
        }
      }
    }
  }

  selectAttr(
    attr: AttributesEntity,
    label: LabelsEntity,
    event: InputEvent,
    mealPlanSide: SidesEntity,
    mealPlanSideItem: ItemsEntity
  ) {
    let foodAttributes = this.selectedFood.attributes;

    if (mealPlanSide) {
      foodAttributes =
        this.selectedFood.sides
          .find((x) => x.id === mealPlanSide.id)
          ?.items.find((y) => y.id == mealPlanSideItem.id)?.attributes || [];
    }

    if ((event.srcElement as HTMLInputElement).type === "radio") {
      for (let i = foodAttributes.length - 1; i >= 0; i--) {
        if (foodAttributes[i].id == attr.id) {
          foodAttributes.splice(i, 1);
        }
      }
      foodAttributes.push({
        id: attr.id,
        name: attr.name,
        description: attr.description,
        labels: [label]
      });
    } else if ((event.srcElement as HTMLInputElement).type === "checkbox") {
      const idx = foodAttributes.findIndex((x) => x.id === attr.id);
      if (idx < 0) {
        foodAttributes.push({
          id: attr.id,
          name: attr.name,
          description: attr.description,
          labels: [label]
        });
      } else {
        let isRemoval = false;
        for (let i = foodAttributes[idx].labels.length - 1; i >= 0; i--) {
          if (foodAttributes[idx].labels[i].id == label.id) {
            foodAttributes[idx].labels.splice(i, 1);
            isRemoval = true;
          }
        }
        if (!isRemoval) {
          foodAttributes[idx].labels.push(label);
        }
      }
      const index = foodAttributes.findIndex((each) => each.id === attr.id);
      const length = foodAttributes[index].labels.length;

      if (index > -1) {
        if (attr.maxChoice > 0 && length > attr.maxChoice) {
          foodAttributes[index].labels.shift();
        }
      }
      for (let i = foodAttributes.length - 1; i >= 0; i--) {
        if (foodAttributes[i].labels.length < 1) {
          foodAttributes.splice(i, 1);
        }
      }
    }
  }

  getItemPrice(amount: number, rate: number) {
    return menuStore.toFormattedTaxInclusive(amount, rate);
  }

  getSelectedSidesQty(
    side: SidesEntity,
    item: ItemsEntity,
    mealPlanSide: SidesEntity,
    mealPlanSideItem: ItemsEntity
  ): string {
    let foodSides = this.selectedFood.sides;

    if (mealPlanSide) {
      foodSides =
        this.selectedFood.sides
          .find((x) => x.id === mealPlanSide.id)
          ?.items.find((y) => y.id == mealPlanSideItem.id)?.sides || [];
    }

    let qtyStr = "";
    const foodSide = foodSides.find((x) => x.id === side.id);
    let length = 0;

    if (foodSide) {
      length = foodSide?.items.filter((each) => each.id === item.id).length;
    }
    qtyStr = length === 0 ? "" : length + "x";
    return qtyStr;
  }

  selectMultipleSides(
    side: SidesEntity,
    item: ItemsEntity,
    e: InputEvent,
    stepQty: number,
    mealPlanSide: SidesEntity,
    mealPlanSideItem: ItemsEntity
  ) {
    e.preventDefault();

    const food = this.selectedFood;
    let foodSide = food.sides.find((x) => x.id === side.id);

    let selectedMealPlanSides;
    if (mealPlanSide) {
      selectedMealPlanSides = food.sides
        .find((x) => x.id === mealPlanSide.id)
        ?.items.find((y) => y.id == mealPlanSideItem.id)?.sides;

      if (selectedMealPlanSides) {
        foodSide = selectedMealPlanSides.find((x) => x.id === side.id);
      }
    }
    let counts = {};

    if (foodSide) {
      if (stepQty > 0) {
        foodSide.items.forEach((each) => {
          counts[each.id] = (counts[each.id] || 0) + 1;
        });

        foodSide.items.push({
          ...(item as SelectedSideItem),
          sides: [],
          attributes: []
        });
        if (foodSide.items.length > side.maxChoice) {
          const highestQtyItemId = Object.keys(counts).reduce(
            (a, b) => (counts[a] > counts[b] ? a : b),
            ""
          );
          let index = foodSide.items.findIndex(
            (each) =>
              each.id === highestQtyItemId &&
              each.sides?.length == 0 &&
              each.attributes?.length == 0
          );
          if (index == -1) {
            index = foodSide.items.findIndex(
              (each) => each.id === highestQtyItemId
            );
          }
          if (index > -1) foodSide.items.splice(index, 1);
        }
      } else {
        let index = foodSide.items.findIndex(
          (each) =>
            each.id === item.id &&
            each.sides?.length == 0 &&
            each.attributes?.length == 0
        );
        if (index == -1) {
          index = foodSide.items.findIndex((each) => each.id === item.id);
        }
        if (index > -1) foodSide.items.splice(index, 1);
      }
    } else {
      if (stepQty > 0) {
        const load = {
          id: side.id,
          name: side.name,
          description: side.description,
          items: [{ ...(item as SelectedSideItem), sides: [], attributes: [] }],
          delivery: side.delivery
        };
        if (mealPlanSide) {
          selectedMealPlanSides?.push(load);
        } else {
          food.sides.push(load);
        }
      }
    }
  }

  selectSides(
    side: SidesEntity,
    item: ItemsEntity,
    event: InputEvent,
    mealPlanSide: SidesEntity,
    mealPlanSideItem: ItemsEntity
  ) {
    let foodSides = this.selectedFood.sides;

    if (mealPlanSide) {
      foodSides =
        this.selectedFood.sides
          .find((x) => x.id === mealPlanSide.id)
          ?.items.find((y) => y.id == mealPlanSideItem.id)?.sides || [];
    }

    if ((event.srcElement as HTMLInputElement).type === "radio") {
      for (let i = foodSides.length - 1; i >= 0; i--) {
        if (foodSides[i].id == side.id) {
          foodSides.splice(i, 1);
        }
      }

      foodSides.push({
        id: side.id,
        name: side.name,
        description: side.description,
        items: [{ ...(item as SelectedSideItem), sides: [], attributes: [] }],
        delivery: side.delivery
      });
    } else if ((event.srcElement as HTMLInputElement).type === "checkbox") {
      const idx = foodSides.findIndex((x) => x.id === side.id);
      if (idx < 0) {
        foodSides.push({
          id: side.id,
          name: side.name,
          description: side.description,
          items: [{ ...(item as SelectedSideItem), sides: [], attributes: [] }],
          delivery: side.delivery
        });
      } else {
        let isRemoval = false;

        for (let i = foodSides[idx].items.length - 1; i >= 0; i--) {
          if (foodSides[idx].items[i].id == item.id) {
            isRemoval = true;
            foodSides[idx].items.splice(i, 1);
          }
        }

        if (!isRemoval) {
          foodSides[idx].items.push({
            ...(item as SelectedSideItem),
            sides: [],
            attributes: []
          });
        }
      }
      const index = foodSides.findIndex((each) => each.id === side.id);
      const length = foodSides[index].items.length;
      if (index > -1) {
        if (side.maxChoice > 0 && length > side.maxChoice) {
          foodSides[index].items.shift();
        }
      }
      for (let i = foodSides.length - 1; i >= 0; i--) {
        if (foodSides[i].items.length < 1) {
          foodSides.splice(i, 1);
        }
      }
    }
  }

  itemHasSelectedAttributes(side: SidesEntity, item: ItemsEntity) {
    const attrs =
      this.selectedFood.sides
        .find((x) => x.id === side.id)
        ?.items.find((i) => (i.id = item.id))?.attributes || [];
    return attrs.length > 0;
  }

  isAttrChecked(
    attr: AttributesEntity,
    label: LabelsEntity,
    mealPlanSide: SidesEntity,
    mealPlanSideItem: ItemsEntity
  ): boolean {
    let foodAttributes = this.selectedFood.attributes;

    if (mealPlanSide) {
      foodAttributes =
        this.selectedFood.sides
          .find((x) => x.id === mealPlanSide.id)
          ?.items.find((y) => y.id == mealPlanSideItem.id)?.attributes || [];
    }

    const index = foodAttributes.findIndex((x) => x.id === attr.id);
    if (index == -1) return false;
    const data = foodAttributes[index].labels.find((x) => x.id === label.id);
    if (data) return true;
    else return false;
  }

  itemHasSelectedSides(side: SidesEntity, item: ItemsEntity) {
    const sides =
      this.selectedFood.sides
        .find((x) => x.id === side.id)
        ?.items.find((i) => (i.id = item.id))?.sides || [];
    return sides.length > 0;
  }

  isSideChecked(
    side: SidesEntity,
    item: ItemsEntity,
    mealPlanSide: SidesEntity,
    mealPlanSideItem: ItemsEntity
  ): boolean {
    let foodSides = this.selectedFood.sides;

    if (mealPlanSide) {
      foodSides =
        this.selectedFood.sides
          .find((x) => x.id === mealPlanSide.id)
          ?.items.find((y) => y.id == mealPlanSideItem.id)?.sides || [];
    }

    const index = foodSides.findIndex((x) => x.id === side.id);

    if (index == -1) return false;
    const data = foodSides[index].items.find((x) => x.id === item.id);
    if (data) return true;
    else return false;
  }

  get availableFoodQuantity() {
    const quantity = this.food?.quantity;
    if (quantity == null) return -1;
    else {
      const currentQuantity = cartStore.cart
        .filter((each) => each.item.id === this.food?.id)
        .filter((each) => each.cartItemID !== this.cartId)
        .reduce((acc, b) => acc + b.itemQuantity, 0);
      return quantity - currentQuantity - this.quantity;
    }
  }

  get formattedDescription() {
    const isUsePlatformDetail = miniProgramPlatforms.includes(
      merchantStore.platform
    );
    const description = isUsePlatformDetail
      ? !empty(
          this.food?.platformDetail?.language?.[this.$i18n.locale]?.description
        )
        ? this.food?.platformDetail?.language?.[this.$i18n.locale]?.description
        : this.food?.description
      : !empty(this.food?.language?.[this.$i18n.locale]?.description)
      ? this.food?.language?.[this.$i18n.locale]?.description
      : this.food?.description;

    return (linkifyHtml(description) || "").replace(
      /(?:\r\n|\r|\n)/g,
      "<br />"
    );
  }

  created() {
    dayjs.extend(customParseFormat);
  }

  addFood() {
    this.quantity++;
  }

  removeFood() {
    if (this.quantity > 0) this.quantity--;
  }
  updateCart() {
    const { sides, attributes } = this.selectedFood;

    sides.sort((x, y) => {
      if (x.id > y.id) return 1;
      if (y.id > x.id) return -1;
      return 0;
    });

    attributes.sort((x, y) => {
      if (x.id > y.id) return 1;
      if (y.id > x.id) return -1;
      return 0;
    });

    const item = {
      cartId: this.cartId,
      food: this.food,
      selectedSides: sides,
      selectedAttributes: attributes,
      combinationPricePerItem: this.calculateTotalPrice * 1.0,
      comboTaxAmount: this.comboTaxAmount,
      campaignDiscountAmount:
        this.campaignPrice > -1
          ? this.calculateTotalPrice - this.campaignPrice
          : 0,
      itemQuantity: this.quantity,
      remark: this.remark,
      selectedDineTime: this.dineTime,
      selectedDineType: this.isMealPlan ? "MEAL_PLAN" : "",
      mealPlan: this.food?.mealPlan
    };
    cartStore.addToCart(item);
  }

  async preCheckOut() {
    // if any item in form not chosen, dont checkout and scroll to empty field
    if (this.isCheckoutDisabled) {
      await this.$nextTick();
      const form: any = document.querySelector("form");
      if (!form.reportValidity()) return;
    } else if (
      // Next, we check if there is already a regular food item in their cart but the user is trying to check out a meal plan
      (this.isMealPlan || this.isCartContainMealPlan()) &&
      cartStore.cart.length > 0 &&
      this.cartId === ""
    ) {
      // First, we check if there is already a meal plan in their cart but they are trying to check out a regular food item
      this.$ionic.alertController
        .create({
          header: "Clear cart ?",
          // subHeader: "Subtitle",
          message: this.isMealPlan
            ? "Checking out a meal plan will clear your current cart"
            : "Checking out a regular food item will clear the meal plan in your cart",

          buttons: [
            {
              text: "Cancel",
              role: "cancel",
              cssClass: "secondary",
              handler: () => {
                console.log("Confirm Cancel");
              }
            },
            {
              text: "Proceed",
              handler: (e) => {
                console.log("Confirm Ok", e);
                cartStore.clearCart();
                cookies.remove("isCutleryRequired");
                cookies.remove(`cart-${this.$route.params.id}`);
                this.checkout();
              }
            }
          ]
        })
        .then((alert) => {
          alert.present();
        });
    } else {
      this.checkout();
    }
  }

  showResetDatesPrompt(e, deliverAt) {
    this.$ionic.alertController
      .create({
        header: "Starting over ?",
        // subHeader: "Subtitle",
        message: "Proceeding will reset the dates you've selected so far",

        buttons: [
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {
              console.log("Confirm Cancel");
            }
          },
          {
            text: "Proceed",
            handler: () => {
              deliverAt = dayjs(e).toISOString();
              this.minDate = dayjs(e);
              this.findMealPlanDates();
              this.$forceUpdate();
            }
          }
        ]
      })
      .then((alert) => {
        alert.present();
      });
  }
  checkout() {
    this.updateCart();
    if (this.cartId !== "") {
      this.$router.go(-1);
    } else {
      this.$router.replace(`/${this.$route.params.id}/checkout`);
    }
  }

  backPressed() {
    const { from } = this.$router.currentRoute.query;

    if (
      window.history.length == merchantStore.initHistoryLen &&
      merchantStore.currentStore &&
      from != "merchantInfo"
    ) {
      this.$router.replace({
        name: "StoreView",
        params: { id: merchantStore.currentStore.id }
      });
    } else {
      this.$router.go(-1);
    }
  }

  onRemove() {
    cartStore.removeItemfromCartById(this.cartId);
    this.updateCart();
    this.$router.go(-1);
  }

  async handleAddCart() {
    // if any item in form not chosen, dont checkout and scroll to empty field
    if (this.isCheckoutDisabled) {
      await this.$nextTick();
      const form: any = document.querySelector("form");
      if (!form.reportValidity()) return;
    } else {
      this.updateCart();
      const { from } = this.$router.currentRoute.query;
      if (from == "merchantInfo") {
        this.$router.replace(`/${this.$route.params.id}/home`);
      } else {
        this.$router.go(-1);
      }
    }
  }

  get isDesktop() {
    return window.outerWidth >= 1024;
  }

  get isTablet() {
    return window.outerWidth >= 768 && window.outerWidth < 1024;
  }

  get isMobile() {
    return !this.isTablet && !this.isDesktop;
  }

  get isCheckoutDisabled() {
    let validate = false;
    if (this.food !== null) {
      if (
        Object.keys(this.food).length === 0 &&
        this.food.constructor === Object
      ) {
        return true;
      }
      const item = this.food as IFood;
      (item.attributes as AttributesEntity[])?.map((each) => {
        if (each.labels && each.labels.length > 0) {
          const selectedAttribute = this.selectedFood.attributes.find(
            (x) => x.id === each.id
          );
          each.isRequired = false;
          if (selectedAttribute) {
            if (
              selectedAttribute.labels.length < each.minChoice &&
              selectedAttribute.labels.length < each.maxChoice
            ) {
              validate = true;
              each.isRequired = true;
            }
          } else if (each.minChoice > 0) {
            validate = true;
            each.isRequired = true;
          }
        }
      });
      (item.sides as SidesEntity[])?.map((each) => {
        if (each.items && each.items.length > 0) {
          const selectedSide = this.selectedFood.sides.find(
            (x) => x.id === each.id
          );
          each.isRequired = false;
          if (selectedSide) {
            if (
              selectedSide.items.length < each.minChoice &&
              selectedSide.items.length < each.maxChoice
            ) {
              validate = true;
              each.isRequired = true;
            } else {
              selectedSide.items?.forEach((selectedOpt) => {
                const item = each.items?.find((i) => i?.id == selectedOpt.id);

                (item?.sides as SidesEntity[])?.map((side) => {
                  if (side.items && side.items.length > 0) {
                    const selectedSide = this.selectedFood.sides
                      .find((x) => x.id === each.id)
                      ?.items.find((i) => i.id == item?.id)
                      ?.sides?.find((s) => s.id == side.id);

                    if (selectedSide) {
                      side.isRequired = false;
                      if (
                        selectedSide.items.length < side.minChoice &&
                        selectedSide.items.length < side.maxChoice
                      ) {
                        validate = true;
                        each.isRequired = false;
                        side.isRequired = true;
                      }
                    } else if (side.minChoice > 0) {
                      validate = true;
                      each.isRequired = false;
                      side.isRequired = true;
                    }
                  }
                });
                (item?.attributes as AttributesEntity[])?.map((attr) => {
                  if (attr.labels && attr.labels.length > 0) {
                    const selectedAttribute = this.selectedFood.sides
                      .find((x) => x.id === each.id)
                      ?.items.find((i) => i.id == item?.id)
                      ?.attributes?.find((s) => s.id == attr.id);
                    if (selectedAttribute) {
                      attr.isRequired = false;
                      if (
                        selectedAttribute.labels.length < attr.minChoice &&
                        selectedAttribute.labels.length < attr.maxChoice
                      ) {
                        validate = true;
                        each.isRequired = false;
                        attr.isRequired = true;
                      }
                    } else if (attr.minChoice > 0) {
                      validate = true;
                      each.isRequired = false;
                      attr.isRequired = true;
                    }
                  }
                });
              });
            }
          } else if (each.minChoice > 0) {
            validate = true;
            each.isRequired = true;
          }
        }
      });
    }
    this.$forceUpdate();

    return (
      validate ||
      this.quantity < 1 ||
      (this.isMealPlan && !this.isFirstDaySelected)
    );
  }
  get calculateTotalPrice() {
    let totalAmount = this.food?.price.amount || 0;
    this.comboTaxAmount =
      ((this.food?.price?.amount || 0) * (this.food?.tax?.rate || 0)) / 10000;

    let sides = [...this.selectedFood.sides];
    if (this.isMealPlan) {
      sides = [];
      this.selectedFood.sides.forEach((side) =>
        side.items.forEach((item) => {
          let total = side.items.reduce((total, it) => {
            if (item.id == it.id) {
              total++;
            }
            return total;
          }, 0);
          if (item.sides && item.sides?.length > 0) {
            for (let i = total; i > 0; i--) {
              sides = [...sides, ...(item.sides || [])];
            }
          }
        })
      );
    }

    sides.forEach((each) => {
      each.items.forEach((item) => {
        totalAmount += item.price.amount;
        // Side item can be water, or coke, so tax on the side item can be SR6 or ZRL
        this.comboTaxAmount +=
          (item.price.amount * (item.tax?.rate || 0)) / 10000;
      });
    });

    return totalAmount;
  }

  isCartContainMealPlan() {
    return cartStore.cart.some((v) => v.selectedDineType == "MEAL_PLAN");
  }

  async activated() {
    this.foodId = this.$route.params?.foodId;

    let food: IFood | undefined = {} as IFood;
    food =
      menuStore.allFoods?.find((food) => food?.id === this.foodId) || undefined;
    if (food) {
      this.processFood(food);
    }
    foodStore.fetchFood(this.foodId).then((food) => {
      this.processFood(food as IFood);
    });
  }
  deactivated() {
    this.food = null;
    this.$destroy();
  }
}
